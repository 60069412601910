import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import FileUploader from './components/FileUploader'; // Keep this import
import './App.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function App() {
  const [companies, setCompanies] = useState([]);
  const [showImporter, setShowImporter] = useState(false); // New state to control visibility

  useEffect(() => {
    async function fetchCompanies() {
      const companyCollection = collection(db, 'companies');
      const companySnapshot = await getDocs(companyCollection);
      const companyList = companySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const companiesWithUrls = await Promise.all(companyList.map(async (company) => {
        try {
          const logoUrl = company.logo ? await getDownloadURL(ref(storage, company.logo)) : null;
          return { 
            ...company, 
            logoUrl,
            url: typeof company.url === 'object' ? company.url.text : company.url
          };
        } catch (error) {
          console.log("Error getting logo URL: ", error);
          return company;
        }
      }));

      setCompanies(companiesWithUrls);
    }

    fetchCompanies();
  }, []);

  const handleCompanyClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div>
      <div className="title-banner">
        <h1>Toledo Tech Directory</h1>
      </div>
      <div className="container">
        <div id="company-list">
          {companies.map((company) => (
            <div key={company.id} className="company" onClick={() => handleCompanyClick(company.url)}>
              {company.logoUrl ? (
                <img src={company.logoUrl} alt={company.organization_name} title={company.organization_name} />
              ) : (
                <div className="company-name">{company.organization_name}</div>
              )}
            </div>
          ))}
        </div>
        
        {/* FileUploader component - now conditionally rendered */}
        {showImporter && (
          <div className="importer-section">
            <h2>Import Company Data</h2>
            <FileUploader />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
