import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import ExcelJS from 'exceljs';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function sanitizeFieldName(name) {
  return name.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_]/g, '');
}

async function importData(file) {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());
    const worksheet = workbook.worksheets[0];

    console.log('Worksheet loaded successfully');

    // Find the index of each column
    const headerRow = worksheet.getRow(1);
    const nameCol = headerRow.values.findIndex(h => h === 'Organization Name');
    const urlCol = headerRow.values.findIndex(h => h === 'URL');
    const logoCol = headerRow.values.findIndex(h => h === 'Logo');

    console.log('Column indices:', { nameCol, urlCol, logoCol });

    if (nameCol === -1 || urlCol === -1 || logoCol === -1) {
      throw new Error('Required columns not found in the spreadsheet');
    }

    // Get all images from the worksheet
    const images = worksheet.getImages();
    console.log(`Found ${images.length} images in the worksheet`);

    for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
      const row = worksheet.getRow(rowNumber);
      const name = row.getCell(nameCol).value;
      const url = row.getCell(urlCol).value;

      console.log(`Processing row ${rowNumber}:`, { name, url });

      if (!name || !url) {
        console.error(`Skipping row ${rowNumber} due to missing required fields`);
        continue;
      }

      // Find image for this row
      const rowImage = images.find(img => img.range.tl.nativeRow === rowNumber - 1 && img.range.tl.nativeCol === logoCol - 1);

      let logoFileName = null;
      if (rowImage) {
        console.log(`Found image for ${name}`);
        const imageId = rowImage.imageId;
        const image = workbook.model.media.find(m => m.index === imageId);
        
        if (image) {
          console.log('Image found:', { type: image.type, size: image.buffer.length });
          const imageData = image.buffer;
          const extension = image.extension;
          logoFileName = `logos/${name.replace(/\s+/g, '_')}.${extension}`;
          const logoRef = ref(storage, logoFileName);
          await uploadBytes(logoRef, imageData);
          console.log(`Uploaded logo for ${name}`);
        } else {
          console.warn(`No image data found for ${name}`);
        }
      } else {
        console.warn(`No logo found for ${name}`);
      }

      // Prepare document data for Firestore
      const docData = {
        organization_name: name,
        url: url
      };
      if (logoFileName) {
        docData.logo = logoFileName;
      }

      // Add any additional columns
      headerRow.values.forEach((header, index) => {
        if (index !== nameCol && index !== urlCol && index !== logoCol) {
          const value = row.getCell(index).value;
          if (value !== null && value !== undefined && value !== '') {
            docData[sanitizeFieldName(header)] = value;
          }
        }
      });

      // Add company data to Firestore
      await addDoc(collection(db, 'companies'), docData);

      console.log(`Imported ${name}`);
    }

    console.log('Import completed successfully!');
  } catch (error) {
    console.error('Error importing data:', error);
    throw error;
  }
}

export default importData;
