import React, { useState } from 'react';
import importData from '../utils/ImportData'

function FileUploader() {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file first!');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      await importData(file);
      setMessage('Data imported successfully!');
    } catch (error) {
      console.error('Error during import:', error);
      setMessage(`Error importing data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept=".xlsx,.xls" />
      <button onClick={handleUpload} disabled={!file || isLoading}>
        {isLoading ? 'Importing...' : 'Import Data'}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default FileUploader;
